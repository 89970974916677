





















































import { Component, Provide, ProvideReactive } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import TheoryExamList from "@/views/Exam/TheoryExam/TheoryExamList.vue";
import Actions from "@/components/Actions.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import CopyButton from "@/components/Button/CopyButton.vue";
import VerticalDivider from "@/components/VerticalDivider.vue";
import EditButton from "@/components/Button/EditButton.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import ArchiveButton from "@/components/Button/ArchiveButton.vue";
import FilterButton from "@/components/Button/FilterButton.vue";
import { ITheoryExam } from "@/interfaces/Exam/ITheoryExam";
import ArchiveModal from "@/components/Modal/ArchiveModal.vue";
import ModalMixin from "@/mixins/ModalMixin";
import { mixins } from "vue-class-component";
import TheoryExamInfo from "@/views/Exam/TheoryExam/Exam/TheoryExamInfo.vue";
import TheoryExamParticipants from "@/views/Exam/TheoryExam/Participants/TheoryExamParticipants.vue";
import moment from "moment";
import ExamMixin from "@/mixins/ExamMixin";
import FilterAndSearch from "@/components/FilterAndSearch.vue";
import FuncMixins from "@/mixins/FuncMixins";
import DeleteModal from "@/components/Modal/DeleteModal.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";
import { ExamModeEnum } from "@/enums/ExamModeEnum";
import TheoryExamRequestMixin from "@/mixins/Request/TheoryExamRequestMixin";

@Component({
  components: {
    DeleteModal,
    FilterAndSearch,
    TheoryExamParticipants,
    TheoryExamInfo,
    ArchiveModal,
    FilterButton,
    ArchiveButton,
    RemoveButton,
    EditButton,
    VerticalDivider,
    CopyButton,
    CreateButton,
    Actions,
    TheoryExamList,
    FscPageHeader,
    FscMultiselect,
  },
})
export default class TheoryExam extends mixins(ModalMixin, ExamMixin, FuncMixins, TheoryExamRequestMixin) {
  public name = "TheoryExam";

  private selectedExam: ITheoryExam | null = null;

  private examMode = ExamModeEnum.add;
  private showList = true;

  // participants/studentsSelect
  private form = "participants";

  public archived = false;

  public deleteTheoryExamModalId = "delete-theory-exam-modal-id";

  // Archive Theory exam
  @Provide("archiveTheoryExam")
  private async archiveTheoryExamFn({ params }: { params: { id: number; archived: boolean } }) {
    await this.archiveTheoryExam(params.id, params.archived);
    if (this.archiveTheoryExamSuccess) {
      this.selectedExam = null;
      this.onCloseTheoryExamParticipants();
      this.loadExams();
    }
  }

  @ProvideReactive("archiveTheoryExamSuccess")
  private get archiveSuccess() {
    return this.archiveTheoryExamSuccess;
  }

  @ProvideReactive("archiveTheoryExamLoading")
  private get archiveTheoryExamLoadingFn() {
    return this.archiveTheoryExamLoading;
  }

  // Get Theory exam
  @Provide("fetchTheoryExam")
  public async fetchTheoryExamFn(examId: number): Promise<void> {
    await this.fetchTheoryExam(examId);
  }

  @ProvideReactive("exam")
  public get exam() {
    return this.theoryExam;
  }

  @ProvideReactive("theoryExamLoading")
  public get theoryExamLoadingFn() {
    return this.theoryExamLoading;
  }

  @Provide("createTheoryExam")
  protected async createTheoryExamFn(exam: any): Promise<void> {
    await this.createTheoryExam(exam);

    if (this.createTheoryExamSuccess) {
      this.showList = true;
      this.loadExams();
    }
  }

  @ProvideReactive("createTheoryExamSuccess")
  protected get createTheoryExamSuccessFn() {
    return this.createTheoryExamSuccess;
  }

  @ProvideReactive("createTheoryExamLoading")
  protected get createTheoryExamLoadingFn() {
    return this.createTheoryExamLoading;
  }

  @Provide("updateTheoryExam")
  protected async updateTheoryExamFn(exam: any): Promise<void> {
    await this.updateTheoryExam(exam.id, exam);

    if (this.updateTheoryExamSuccess) {
      this.showList = true;
      this.loadExams();
      this.fetchTheoryExam(exam.id);
    }
  }

  @ProvideReactive("updateTheoryExamLoading")
  protected get updateTheoryExamLoadingFn() {
    return this.updateTheoryExamLoading;
  }

  @Provide("deleteTheoryExam")
  protected async deleteTheoryExamFn(theoryExamId: number): Promise<void> {
    return await this.deleteTheoryExam(theoryExamId);
  }

  @ProvideReactive("deleteTheoryExamSuccess")
  private get getDeleted() {
    return this.deleteTheoryExamSuccess;
  }
  @ProvideReactive("deleteTheoryExamLoading")
  private get deleteTheoryExamLoadingFn() {
    return this.deleteTheoryExamLoading;
  }

  @Provide("deleteParticipantsTheoryExam")
  protected async deleteParticipantsTheoryExamFn(theoryExamId: number): Promise<void> {
    await this.deleteParticipantsTheoryExam(theoryExamId);
  }

  @ProvideReactive("deleteParticipantsTheoryExamSuccess")
  protected get deleteParticipantsTheoryExamSuccessFn() {
    return this.deleteParticipantsTheoryExamSuccess;
  }

  @Provide("deleteParticipantsTheoryExamLoading")
  protected get deleteParticipantsTheoryExamLoadingFn() {
    return this.deleteParticipantsTheoryExamLoading;
  }

  // Update Theory exam status
  @Provide("updateTheoryExamStatus")
  public async updateTheoryExamStatusFn(data: any): Promise<void> {
    return await this.updateTheoryExamStatus(data);
  }

  @ProvideReactive("updateTheoryExamStatusSuccess")
  public get updateTheoryExamStatusSuccessFn() {
    return this.updateTheoryExamStatusSuccess;
  }

  @ProvideReactive("updateTheoryExamStatusLoading")
  public get updateTheoryExamStatusLoadingFn() {
    return this.updateTheoryExamStatusLoading;
  }

  // Book Theory exam
  @Provide("bookTheoryExam")
  public async bookTheoryExamFn(theoryExamId: number): Promise<void> {
    return await this.bookTheoryExam(theoryExamId);
  }

  @ProvideReactive("bookTheoryExamSuccess")
  public get bookTheoryExamSuccessFn() {
    return this.bookTheoryExamSuccess;
  }

  @ProvideReactive("bookTheoryExamLoading")
  public get bookTheoryExamLoadingFn() {
    return this.bookTheoryExamLoading;
  }

  // Upload Theory exam protocol
  @Provide("uploadProtocolTheoryExam")
  public async uploadProtocolTheoryExamFn(data: any): Promise<void> {
    return await this.uploadProtocolTheoryExam(data);
  }

  @ProvideReactive("uploadProtocolTheoryExamSuccess")
  public get uploadProtocolTheoryExamSuccessFn() {
    return this.uploadProtocolTheoryExamSuccess;
  }

  @ProvideReactive("uploadProtocolTheoryExamLoading")
  public get uploadProtocolTheoryExamLoadingFn() {
    return this.uploadProtocolTheoryExamLoading;
  }

  // Participant Info Theory exam
  @Provide("participantInfoTheoryExam")
  public async participantInfoTheoryExamFn(studentEducationId: number): Promise<void> {
    return await this.participantInfoTheoryExam(studentEducationId);
  }

  @ProvideReactive("participantInfoTheoryExamSuccess")
  public get participantInfoTheoryExamSuccessFn() {
    return this.participantInfoTheoryExamSuccess;
  }

  @ProvideReactive("participantInfoTheoryExamData")
  public get participantInfoTheoryExamDataFn() {
    return this.participantInfoTheoryExamData;
  }

  // Book Theory exam Product
  @Provide("bookTheoryExamProduct")
  public async bookTheoryExamProductFn(studentEducationId: number, studentTheoryExamId: number): Promise<void> {
    return await this.bookTheoryExamProduct(studentEducationId, studentTheoryExamId);
  }

  @ProvideReactive("bookTheoryExamProductSuccess")
  public get bookTheoryExamProductSuccessFn() {
    return this.bookTheoryExamProductSuccess;
  }

  @ProvideReactive("bookTheoryExamProductError")
  public get bookTheoryExamProductErrorFn() {
    return this.bookTheoryExamProductError;
  }

  @ProvideReactive("bookTheoryExamProductLoading")
  public get bookTheoryExamProductLoadingFn() {
    return this.bookTheoryExamProductLoading;
  }

  // Update Participants Theory exam
  @Provide("updateParticipantsTheoryExams")
  public async updateParticipantsTheoryExamsFn(data: any): Promise<void> {
    return await this.updateParticipantsTheoryExams(data);
  }

  @ProvideReactive("updateParticipantsTheoryExamsSuccess")
  public get updateParticipantsTheoryExamsSuccessFn() {
    return this.updateParticipantsTheoryExamsSuccess;
  }

  @ProvideReactive("updateParticipantsTheoryExamsLoading")
  public get updateParticipantsTheoryExamsLoadingFn() {
    return this.updateParticipantsTheoryExamsLoading;
  }

  // Theory exam filter
  @Provide("theoryExams")
  public async theoryExamsFn(filter: any): Promise<void> {
    return await this.theoryExams(filter);
  }

  @ProvideReactive("theoryExamsData")
  public get theoryExamsDataFn() {
    return this.theoryExamsData;
  }

  @ProvideReactive("theoryExamsTotalRow")
  public get theoryExamsTotalRowFn() {
    return this.theoryExamsTotalRow;
  }

  @ProvideReactive("theoryExamsLoading")
  public get theoryExamsLoadingFn() {
    return this.theoryExamsLoading;
  }

  // Ready theory exam
  @Provide("readyTheoryExam")
  public async readyTheoryExamFn(theoryExamId: number): Promise<void> {
    return await this.readyTheoryExam(theoryExamId);
  }

  @ProvideReactive("readyTheoryExamLoading")
  public get readyTheoryExamLoadingFn() {
    return this.readyTheoryExamLoading;
  }

  @ProvideReactive("createDeleteOrUpdateLoadingTheoryExam")
  private get createDeleteOrUpdateLoading() {
    return this.createTheoryExamLoading || this.updateTheoryExamLoading || this.deleteTheoryExamLoading;
  }

  public filterTheoryExam = "";

  public onSearchTheoryExam(value: string): void {
    this.debouncer(() => {
      this.filterTheoryExam = value;
    });
  }

  private addExam(): void {
    this.showList = false;
    this.examMode = ExamModeEnum.add;
  }

  private editExam(): void {
    this.showList = false;
    this.examMode = ExamModeEnum.edit;
  }

  @Provide("loadExams")
  private loadExams(): void {
    const theoryExamList: any = this.$refs.theoryExamList;
    if (theoryExamList as TheoryExamList) {
      theoryExamList.loadExams();
    }
  }

  private async onExamClick(exam: ITheoryExam, parentSelected: boolean): Promise<void> {
    this.selectedExam = Object.assign(exam, {});
    this.openParticipants();
    if (this.selectedExam && this.selectedExam.id) {
      await this.fetchTheoryExam(this.selectedExam.id);
    }
  }

  private onCloseTheoryExamParticipants(): void {
    this.selectedExam = null;
    this.theoryExam = null;
  }

  private showArchiveModal(): void {
    if (!this.selectedExam) {
      return;
    }
    this.showModal("theory-exam-archive-modal");
  }

  public get frontendDate(): string {
    if (this.selectedExam) {
      return moment(this.selectedExam.date).format("DD.MM.YYYY");
    } else {
      return "";
    }
  }

  public get frontendTime(): string {
    if (this.selectedExam) {
      return moment(this.selectedExam.time, [moment.ISO_8601, "HH:mm"]).format("HH:mm");
    } else {
      return "";
    }
  }

  @Provide("openParticipants")
  protected openParticipants(): void {
    this.form = "participants";
  }

  @Provide("openStudentsSelect")
  protected openStudentsSelect(): void {
    this.form = "studentsSelect";
  }

  public onDeleteTheoryExam(): void {
    if (this.hasNotPermissionWrite) return;
    this.$bvModal.show(this.deleteTheoryExamModalId);
  }

  public async okDeleteTheoryExam(): Promise<void> {
    const theoryExamId = this.selectedExam?.id;
    if (theoryExamId) {
      await this.deleteTheoryExam(theoryExamId);

      if (this.getDeleted) {
        this.onCloseTheoryExamParticipants();
        this.loadExams();
      }
    }
  }

  private get archivedOption(): Array<any> {
    return [
      {
        text: this.$tc("general.active", 2),
        value: false,
      },
      {
        text: this.$tc("general.archived", 2),
        value: true,
      },
    ];
  }
}
