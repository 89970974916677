




import { Component, Vue, Inject, Prop } from "vue-property-decorator";
import TheoryExamForm from "@/views/Exam/TheoryExam/Exam/TheoryExamForm.vue";

@Component({
  components: {
    TheoryExamForm,
  },
})
export default class TheoryExamCreate extends Vue {
  public name = "TheoryExamCreate";

  @Prop()
  private loading: any;

  @Inject("createTheoryExam")
  protected createTheoryExam: any;

  private onSubmit(exam: any): void {
    this.createTheoryExam(exam);
  }

  private get isLoading() {
    return this.loading;
  }

  private onClose(): void {
    this.$emit("on-close");
  }
}
