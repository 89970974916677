











































































import { Component, Inject, InjectReactive, Prop, Watch } from "vue-property-decorator";
import FscCard from "@/components/Card/FscCard.vue";
import Validation from "@/components/Validation.vue";
import Datepicker from "@/components/Datepicker.vue";
import TimePicker from "@/components/TimePicker.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import CopyButton from "@/components/Button/CopyButton.vue";
import VerticalDivider from "@/components/VerticalDivider.vue";
import EditButton from "@/components/Button/EditButton.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import ArchiveButton from "@/components/Button/ArchiveButton.vue";
import FilterButton from "@/components/Button/FilterButton.vue";
import Actions from "@/components/Actions.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import MailButton from "@/components/Button/MailButton.vue";
import InfoButton from "@/components/Button/InfoButton.vue";
import Table from "@/components/Table.vue";
import FscTable from "@/components/Table/FscTable.vue";
import { IStudent } from "@/interfaces/IStudent";
import { removeObject } from "@/utils/ArrayUtils";
import { IStudentTheoryExam } from "@/interfaces/Exam/IStudentTheoryExam";
import Info from "@/views/Exam/TheoryExam/Participants/Info.vue";
import ExamStudents from "@/views/Exam/Components/ExamStudents.vue";
import Students from "@/views/Exam/Components/Students.vue";
import TheoryExamStudents from "@/views/Exam/TheoryExam/Participants/TheoryExamStudents.vue";
import ModalMixin from "@/mixins/ModalMixin";
import { mixins } from "vue-class-component";
import DeleteModal from "@/components/Modal/DeleteModal.vue";
import ExamMixin from "@/mixins/ExamMixin";
import FilterAndSearch from "@/components/FilterAndSearch.vue";

export interface IStudentExamFormDTO {
  studentId?: number;
  licenseClass?: string;
  examStatusId?: number;
  time?: string | Date | null;
}

@Component({
  components: {
    FilterAndSearch,
    TheoryExamStudents,
    Students,
    ExamStudents,
    Info,
    FscTable,
    Table,
    InfoButton,
    MailButton,
    PrintButton,
    Actions,
    FilterButton,
    ArchiveButton,
    RemoveButton,
    EditButton,
    VerticalDivider,
    CopyButton,
    CreateButton,
    SaveButton,
    AbortButton,
    TimePicker,
    Datepicker,
    Validation,
    FscCard,
    DeleteModal,
  },
})
export default class TheoryExamStudentsSelect extends mixins(ModalMixin, ExamMixin) {
  public name = "TheoryExamStudentsSelect";

  @Prop()
  private examId!: any;

  @Prop()
  public exam: any;

  @Prop({ required: true, default: () => false, type: Boolean })
  public loading!: boolean;

  protected studentFilter = "";

  private selectedExamStudent: IStudentTheoryExam | null = null;

  //remove participants
  @Inject("deleteParticipantsTheoryExam")
  private removeStudent!: (theoryExamId: number) => Promise<void>;

  @InjectReactive({ from: "deleteParticipantsTheoryExamSuccess", default: () => false })
  private removeStudentSuccess: any;

  @InjectReactive({ from: "deleteParticipantsTheoryExamLoading", default: () => false })
  private deleteParticipantsTheoryExamLoading!: boolean;

  // get theory exam
  @Inject("fetchTheoryExam")
  protected fetchTheoryExam!: any;

  @Inject("openParticipants")
  private openParticipants: any;

  // update participants
  @Inject("updateParticipantsTheoryExams")
  private updateTheoryExam!: (data: any) => Promise<void>;

  @InjectReactive("updateParticipantsTheoryExamsSuccess")
  private updateParticipantsTheoryExamsSuccess!: boolean;

  @InjectReactive("updateParticipantsTheoryExamsLoading")
  private updateParticipantsTheoryExamsLoading!: boolean;

  @Inject("loadExams")
  private loadExams!: () => void;

  @InjectReactive("archiveTheoryExamLoading")
  private archiveTheoryExamLoading!: boolean;

  protected examStudents: Array<IStudentTheoryExam> = [];

  private currentSelectAddedStudents: Array<IStudentTheoryExam> = [];

  public deleteTheoryExamStudentExamModalId = "delete-theory-exam-student-exam-modal-id";

  private onRowClicked(student: IStudentTheoryExam): void {
    this.selectedExamStudent = student;
  }

  private removeStudentFromCurrentList(student: IStudentTheoryExam): void {
    removeObject(this.examStudents, student);
  }

  protected async onSubmit(): Promise<void> {
    const studentTheoryExamFormDTOs: Array<IStudentExamFormDTO> = [];

    for (const examStudent of this.examStudents) {
      studentTheoryExamFormDTOs.push({
        studentId: examStudent.student?.id,
        licenseClass: examStudent.licenseClass,
        examStatusId: 1,
        time: examStudent.time,
      });
    }

    await this.updateTheoryExam({
      theoryExamId: this.exam.id,
      studentTheoryExamFormDTOs: studentTheoryExamFormDTOs,
    });

    if (this.updateParticipantsTheoryExamsSuccess) {
      this.$toasted.success(String(this.$t("messages.save_success")));
      this.openParticipants();
      this.fetchTheoryExam(this.examId);
      this.loadExams();
    }
  }

  private onClose(): void {
    this.$emit("on-close");
  }

  private showDeleteModal(): void {
    if (!this.selectedExamStudent) {
      return;
    } else if (this.selectedExamStudent && this.selectedExamStudent.unsaved) {
      this.removeStudentFromCurrentList(this.selectedExamStudent);
    } else {
      this.showModal(this.deleteTheoryExamStudentExamModalId);
    }
  }

  private async onDeleteParticipant(): Promise<void> {
    const id = this.selectedExamStudent?.id;
    if (id) {
      await this.removeStudent(id);
      if (this.removeStudentSuccess) {
        await this.fetchTheoryExam(this.exam.id);
        this.$toasted.success(String(this.$t("messages.delete_success")));
      }
    }
  }

  private onStudentRowDoubleClicked(student: IStudent): void {
    if (!student.activeEducations || student.activeEducations.length === 0 || this.hasNotPermissionWrite) {
      return;
    }

    let examStudent: IStudentTheoryExam = {
      id: student.id,
      student: student,
      time: this.exam.time,
      licenseClass: student.activeEducations[0],
      licenseClasses: student.activeEducations,
      booked: false,
      disabled: false,
      unsaved: true,
    };

    this.examStudents.push(examStudent);
    this.currentSelectAddedStudents.push(examStudent);
  }

  @Watch("exam", { deep: true, immediate: true })
  private onExamChange(exam: any): void {
    this.examStudents = [];
    if (!exam || !exam.studentTheoryExams) return;

    const examStudents: Array<any> = [];

    for (const theoryExam of exam.studentTheoryExams) {
      examStudents.push({
        id: theoryExam.id,
        student: theoryExam.student,
        time: theoryExam.time,
        licenseClass: theoryExam.licenseClass,
        licenseClasses: [theoryExam.licenseClass],
        booked: theoryExam.booked,
        disabled: true,
      });
    }

    this.examStudents = examStudents;

    if (this.currentSelectAddedStudents) {
      this.examStudents = this.examStudents.concat(this.currentSelectAddedStudents);
      this.currentSelectAddedStudents = [];
    }
  }

  @InjectReactive("filterTheoryExam")
  public filterTheoryExam!: string;

  private get isLoading() {
    return this.loading || this.updateParticipantsTheoryExamsLoading || this.archiveTheoryExamLoading, this.deleteParticipantsTheoryExamLoading;
  }
}
