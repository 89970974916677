
















































import { Component, InjectReactive, Prop } from "vue-property-decorator";
import { IStudentPracticalExam } from "@/interfaces/Exam/IStudentPracticalExam";
import Table from "@/components/Table.vue";
import TimePicker from "@/components/TimePicker.vue";
import Avatar from "@/components/Avatars/Avatar.vue";
import { mixins } from "vue-class-component";
import ExamMixin from "@/mixins/ExamMixin";
import { formatStudentName } from "@/utils/NameUtil";

@Component({
  components: { Avatar, TimePicker, Table },
})
export default class TheoryExamStudents extends mixins(ExamMixin) {
  public name = "TheoryExamStudents";

  @Prop()
  public exam!: any;

  @Prop()
  public examStudents!: any;

  public examStudentFields = [
    {
      key: "name",
      label: "",
      sortable: false,
    },
    {
      key: "time",
      label: this.$t("calendar.form_time"),
      sortable: false,
    },
    {
      key: "licenseClass",
      label: this.$t("calendar.class"),
      sortable: false,
    },
  ];

  private onRowClicked(student: IStudentPracticalExam): void {
    this.$emit("row-clicked", student);
  }

  private onRowDoubleClicked(student: IStudentPracticalExam): void {
    this.$emit("row-dblclicked", student);
  }

  protected formatName(item: any): string {
    return formatStudentName(item.student.firstName, item.student.lastName);
  }

  protected formatActiveEducations(item: any): string {
    return item.student.activeEducations?.join(" | ");
  }

  protected isDisabled(item: any): boolean {
    return item.disabled;
  }

  protected get isBooked(): boolean {
    return this.exam.booked;
  }

  @InjectReactive("filterTheoryExam")
  public filterTheoryExam!: string;
}
