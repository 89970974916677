





























































































import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ViewSwitchMixin from "@/mixins/ViewSwitchMixin";
import { IStudentPracticalExam } from "@/interfaces/Exam/IStudentPracticalExam";
import Table from "@/components/Table.vue";
import TimePicker from "@/components/TimePicker.vue";
import Avatar from "@/components/Avatars/Avatar.vue";
import { formatStudentName } from "@/utils/NameUtil";

@Component({
  methods: { formatStudentName },
  components: { Avatar, TimePicker, Table },
})
export default class ExamStudents extends mixins(ViewSwitchMixin) {
  public name = "ExamStudents";

  @Prop()
  public exam!: any;
  @Prop()
  public examStudents!: any;

  public examStudentFields = [
    {
      key: "name",
      label: "",
      sortable: true,
    },
    {
      key: "time",
      label: this.$t("calendar.form_time"),
      sortable: true,
    },
    {
      key: "licenseClass",
      label: this.$t("calendar.class"),
      sortable: true,
    },
  ];
  private onRowClicked(student: IStudentPracticalExam): void {
    this.$emit("row-clicked", student);
  }

  private onRowDoubleClicked(student: IStudentPracticalExam): void {
    this.$emit("row-dblclicked", student);
  }

  private toggleSubLicenseClassExams(student: IStudentPracticalExam): void {
    this.$emit("toggle-subLicense-class-exams", student);
  }

  private onLicenseClassChange(student: IStudentPracticalExam): void {
    this.$emit("on-license-class-change", student);
  }

  private onSubLicenseClassChange(parent: IStudentPracticalExam, child: IStudentPracticalExam): void {
    this.$emit("on-sublicense-class-change", parent, child);
  }
}
