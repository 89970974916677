







































































import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ViewSwitchMixin from "@/mixins/ViewSwitchMixin";
import FscPageHeader from "@/components/FscPageHeader.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import EditButton from "@/components/Button/EditButton.vue";
import VerticalDivider from "@/components/VerticalDivider.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import UploadButton from "@/components/Button/UploadButton.vue";
import FileCsvButton from "@/components/Button/FileCsvButton.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import Actions from "@/components/Actions.vue";
import CopyButton from "@/components/Button/CopyButton.vue";
import ArchiveButton from "@/components/Button/ArchiveButton.vue";
import FilterButton from "@/components/Button/FilterButton.vue";
import { IPracticalExam } from "@/interfaces/Exam/IPracticalExam";
import ExamMixin from "@/mixins/ExamMixin";
import SaveButton from "@/components/Button/SaveButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import CustomSlider from "@/components/Slider/CustomSlider.vue";

@Component({
  components: {
    FilterButton,
    ArchiveButton,
    CopyButton,
    Actions,
    PrintButton,
    FileCsvButton,
    UploadButton,
    RemoveButton,
    VerticalDivider,
    EditButton,
    CreateButton,
    FscPageHeader,
    AbortButton,
    SaveButton,
    CustomSlider,
  },
})
export default class ExamList extends mixins(ViewSwitchMixin, ExamMixin) {
  public name = "ExamList";

  private selectedExam?: IPracticalExam | null = {};

  @Prop()
  public exams!: any;

  @Prop()
  public loading!: any;

  @Prop()
  public currentPerPage!: any;

  @Prop()
  public totalRows!: any;

  public selectedModalExam: any = null;
  public currentPage = 1;

  public onCurrentPageChange(page: any) {
    const offset = page - 1;
    this.$emit("page-change", offset);
  }

  private onClick(exam: IPracticalExam): void {
    this.selectedExam = Object.assign(exam, {});
    this.$emit("on-click", this.selectedExam);
  }

  private ondDblclick(exam: IPracticalExam): void {
    this.selectedExam = Object.assign(exam, {});
    this.$emit("on-dblclick", this.selectedExam);
  }

  protected getSlots(exam: any): number | string {
    const availableSlots = exam.availableSlots || `&#8212;`;
    return `${exam.usedSlots}/${availableSlots}`;
  }

  protected onReady(): void {
    this.$emit("on-ready", this.selectedModalExam);
    this.$bvModal.hide("releaseModal");
  }

  protected onReleaseModal(exam: any) {
    this.selectedModalExam = exam;
    this.$bvModal.show("releaseModal");
  }

  protected onCloseModal() {
    this.selectedModalExam = null;
    this.$bvModal.hide("releaseModal");
  }
}
